import React from 'react';
import PropTypes from 'prop-types';
import DataContainer from '../Data/DataContainer';
import FiltersContainer from '../Filters/FiltersContainer';
import WeeklyReportTable from './WeeklyReportTable';

const WeeklyReports = (props) => (
  <div className="weeklyReports">
    <div className="topRow">
      <DataContainer module="trafficConversion" dataInfo={props.dataInfo} refreshData={props.refreshData} downloadProgress={props.downloadProgress} />
    </div>
    {/* <FiltersContainer module="trafficConversion" dataInfo={props.dataInfo} filterValues={props.filterValues} /> */}
    <FiltersContainer
      module="trafficConversion"
      dataInfo={props.dataInfo}
      filterValues={Object.keys(props.filterValues).length > 0 ? { Park: props.filterValues.Park } : {}}
    />
    <WeeklyReportTable weeklyReportData={props.weeklyReportData} />
  </div>
);

WeeklyReports.propTypes = {
  dataInfo: PropTypes.objectOf(
    PropTypes.shape({
      nbRows: PropTypes.number,
      fields: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  filterValues: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  weeklyReportData: PropTypes.shape({
    CY: PropTypes.arrayOf(
      PropTypes.shape({
        period: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      }),
    ),
    PY: PropTypes.arrayOf(
      PropTypes.shape({
        period: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
      }),
    ),
  }).isRequired,
  refreshData: PropTypes.func.isRequired,
  downloadProgress: PropTypes.number,
};

WeeklyReports.defaultProps = {
  downloadProgress: null,
};

export default WeeklyReports;
